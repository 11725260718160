import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonText, IonTitle, IonToolbar } from '@ionic/react'
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setTransferenciasMEditShow, setTransferenciasMEditHide, setActiveLoading } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  derivacion: "",
  entrada_1: "",
  entrada_2: "",
  corriente_corto_circuito: "",
  numero_celda: "",
  numero_polos: "",
  numero_salida: "",
  tipo: "",
  tipo_aislamiento: "",
  tipo_tarjeta: "",
  voltaje_control: "",
}

export const TransferenciasItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editTransferencias } = useSelector((state: RootState) => state.ui);
  const { ats_transferencias = {}, corriente_corto_circuito = "", derivacion = "", numero_celda = "", numero_polos = "", numero_salida = "", tipo = "", entrada_1 = "" } = activeEquipo;
  const { tipo_aislamiento = "", tipo_tarjeta = "", voltaje_control = "", entrada_2 = "" } = ats_transferencias;

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setTransferenciasMEditShow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        ATS Transferencias
      </IonLabel>
      {(activeEquipo.type === 'ATS_TRANSFERENCIAS' && activeEquipo.tipo) && (<IonList>
        <IonItem lines="none">
          <IonText>entrada 1</IonText>
          <IonNote slot="end">{entrada_1}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>entrada 2</IonText>
          <IonNote slot="end">{entrada_2}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Tipo</IonText>
          <IonNote slot="end">{tipo}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Derivación</IonText>
          <IonNote slot="end">{derivacion}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Voltaje control</IonText>
          <IonNote slot="end">{voltaje_control}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Tipo de tarjeta</IonText>
          <IonNote slot="end">{tipo_tarjeta}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Tipo aislamiento</IonText>
          <IonNote slot="end">{tipo_aislamiento}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText># Celda</IonText>
          <IonNote slot="end">{numero_celda}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText># polos</IonText>
          <IonNote slot="end">{numero_polos}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText># salida</IonText>
          <IonNote slot="end">{numero_salida}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cte Cto Cto</IonText>
          <IonNote slot="end">{corriente_corto_circuito}</IonNote>
        </IonItem>
      </IonList>)}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">{(activeEquipo.type === 'ATS_TRANSFERENCIAS' && (derivacion || voltaje_control)) ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editTransferencias && <TransferenciasModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
}

const TransferenciasModal: FC = () => {
  const dispatch = useDispatch();
  const { editTransferencias } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const [formValues, setFormValues] = useState(initForm);
  const { numero_celda, derivacion, entrada_1, entrada_2, numero_polos, numero_salida, corriente_corto_circuito, tipo, tipo_aislamiento, tipo_tarjeta, voltaje_control } = formValues;

  useEffect(() => {
    if (activeEquipo.ats_transferencias) {
      const e = {
        numero_celda: activeEquipo.numero_celda,
        derivacion: activeEquipo.derivacion,
        entrada_1: activeEquipo.entrada_1,
        entrada_2: activeEquipo.ats_transferencias.entrada_2,
        numero_polos: activeEquipo.numero_polos,
        numero_salida: activeEquipo.numero_salida,
        corriente_corto_circuito: activeEquipo.corriente_corto_circuito,
        tipo: activeEquipo.tipo,
        tipo_aislamiento: activeEquipo.tipo_aislamiento,
        tipo_tarjeta: activeEquipo.ats_transferencias.tipo_tarjeta,
        voltaje_control: activeEquipo.ats_transferencias.voltaje_control,
      }
      setFormValues(e)
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo])

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    ats_transferencias: activeEquipo.ats_transferencias,
    numero_celda: activeEquipo.numero_celda,
    derivacion: activeEquipo.derivacion,
    entrada_1: activeEquipo.entrada_1,
    numero_polos: activeEquipo.numero_polos,
    numero_salida: activeEquipo.numero_salida,
    corriente_corto_circuito: activeEquipo.corriente_corto_circuito,
    tipo: activeEquipo.tipo,
    tipo_aislamiento: activeEquipo.tipo_aislamiento
  }

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.ats_transferencias = {
      entrada_2: entrada_2,
      tipo_tarjeta: tipo_tarjeta,
      voltaje_control: voltaje_control,
    }
    equipo.corriente_corto_circuito = corriente_corto_circuito;
    equipo.derivacion = derivacion;
    equipo.entrada_1 = entrada_1;
    equipo.numero_celda = numero_celda;
    equipo.numero_polos = numero_polos;
    equipo.numero_salida = numero_salida;
    equipo.tipo = tipo;
    equipo.tipo_aislamiento = tipo_aislamiento;
    dispatch(startEquipoUpdated(equipo));
    dispatch(setTransferenciasMEditHide())
  };

  const handleClose = () => {
    dispatch(setTransferenciasMEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editTransferencias} cssClass='modal-celdasMT'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>ATS Transferencias</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Entrada 1
              </IonLabel>
              <IonInput
                type="text"
                name="entrada_1"
                value={entrada_1}
                onIonChange={handleInputChange}
                placeholder="Entrada 1"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Entrada 2
              </IonLabel>
              <IonInput
                type="text"
                name="entrada_2"
                value={entrada_2}
                onIonChange={handleInputChange}
                placeholder="Entrada 2"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Tipo
              </IonLabel>
              <IonInput
                type="text"
                name="tipo"
                value={tipo}
                onIonChange={handleInputChange}
                placeholder="Tipo"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Derivación
              </IonLabel>
              <IonInput
                type="text"
                name="derivacion"
                value={derivacion}
                onIonChange={handleInputChange}
                placeholder="Derivación"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Voltaje de control
              </IonLabel>
              <IonInput
                type="text"
                name="voltaje_control"
                value={voltaje_control}
                onIonChange={handleInputChange}
                placeholder="Voltaje de control"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Tipo de tarjeta
              </IonLabel>
              <IonInput
                type="text"
                name="tipo_tarjeta"
                value={tipo_tarjeta}
                onIonChange={handleInputChange}
                placeholder="Tipo de tarjeta"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Tipo de aislamiento
              </IonLabel>
              <IonInput
                type="text"
                name="tipo_aislamiento"
                value={tipo_aislamiento}
                onIonChange={handleInputChange}
                placeholder="Tipo de aislamiento"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número de celda
              </IonLabel>
              <IonInput
                type="text"
                name="numero_celda"
                value={numero_celda}
                onIonChange={handleInputChange}
                placeholder="Número de celda"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número de polos
              </IonLabel>
              <IonInput
                type="number"
                name="numero_polos"
                value={numero_polos}
                onIonChange={handleInputChange}
                placeholder="Número de polos"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Número de salida
              </IonLabel>
              <IonInput
                type="text"
                name="numero_salida"
                value={numero_salida}
                onIonChange={handleInputChange}
                placeholder="Número de salida"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Corriente corto circuito
              </IonLabel>
              <IonInput
                type="text"
                name="corriente_corto_circuito"
                value={corriente_corto_circuito}
                onIonChange={handleInputChange}
                placeholder="Corriente corto circuito"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}
