import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonModal,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from "ionicons/icons";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoadCanalizaciones } from "../../../actions/canalizaciones";
import { startEquipoUpdated } from "../../../actions/equipos";
import {
  setActiveLoading,
  setCanalizacionesEditHide,
  setCanalizacionesEditShow,
} from "../../../actions/ui";
import { RootState } from "../../../reducers/rootReducer";
import { IonList } from '@ionic/react';

const initForm = {
  canalizacion: "",
  desde: "",
  hasta: "",
  numero_ductos_diametro: "",
};

export const CanalizacionesItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editCanalizaciones } = useSelector((state: RootState) => state.ui);
  const { canalizacion = {} } = activeEquipo
  const { canalizacion: canaliza = "", desde = "", hasta = "", numero_ductos_diametro = "", } = canalizacion;
  const handleDelete = () => {
    console.log("click");
  };
  const handleUpdate = () => {
    dispatch(setCanalizacionesEditShow());
  };
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Canalizaciones
      </IonLabel>
      {Object.keys(canalizacion).length !== 0 && <IonList>
        <IonItem lines="none">
          <IonText>Desde</IonText>
          <IonNote slot="end">{desde}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Hasta</IonText>
          <IonNote slot="end">{hasta}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Canalización</IonText>
          <IonNote slot="end">{canaliza.name}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Número ductos</IonText>
          <IonNote slot="end">{numero_ductos_diametro}</IonNote>
        </IonItem>
      </IonList>}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">{Object.keys(canalizacion).length !== 0 ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editCanalizaciones && <CanalizacionModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption
            color="light"
            onClick={() => {
              handleUpdate();
            }}
          >
            <IonIcon
              className="icon-list"
              color="success"
              ariaLabel="Editar"
              title="Editar"
              icon={createOutline}
            ></IonIcon>
          </IonItemOption>
          <IonItemOption
            color="light"
            onClick={() => {
              handleDelete();
            }}
          >
            <IonIcon
              className="icon-list"
              color="danger"
              icon={trashOutline}
            ></IonIcon>
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  );
};

const CanalizacionModal: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadCanalizaciones());
  }, [dispatch]);

  const { canalizaciones } = useSelector(
    (state: RootState) => state.canalizacion
  );
  const { editCanalizaciones } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { canalizacion, desde, hasta, numero_ductos_diametro } = formValues;

  useEffect(() => {
    if (activeEquipo.canalizacion) {
      const canalizacion = {
        canalizacion: activeEquipo.canalizacion.canalizacion._id,
        desde: activeEquipo.canalizacion.desde,
        hasta: activeEquipo.canalizacion.hasta,
        numero_ductos_diametro: activeEquipo.canalizacion.numero_ductos_diametro,
      };
      setFormValues(canalizacion);
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo.canalizacion]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    canalizacion: activeEquipo.canalizacion,
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.canalizacion = {
      desde: desde,
      hasta: hasta,
      canalizacion: canalizacion,
      numero_ductos_diametro: numero_ductos_diametro,
    };
    dispatch(startEquipoUpdated(equipo));
    dispatch(setCanalizacionesEditHide());
  };

  const handleClose = () => {
    dispatch(setCanalizacionesEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editCanalizaciones} cssClass="modal-central">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Canalizaciones</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
              <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Desde
              </IonLabel>
              <IonInput
                type="text"
                name="desde"
                value={desde}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Desde"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Hasta
              </IonLabel>
              <IonInput
                type="text"
                name="hasta"
                value={hasta}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Hasta"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>Tipo canalizacion</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonSelect
                name="canalizacion"
                value={canalizacion}
                onIonChange={handleInputChange}
              >
                {canalizaciones.map((canalizacion: any) => (
                  <IonSelectOption
                    key={canalizacion.id}
                    value={canalizacion.id}
                  >
                    {canalizacion.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Numero de Ductos - Diametro
              </IonLabel>
              <IonInput
                type="text"
                name="numero_ductos_diametro"
                value={numero_ductos_diametro}
                onIonChange={handleInputChange}
                autocomplete="off"
                placeholder="Numero de Ductos - Diametro"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};
