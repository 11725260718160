import { IonAlert, IonButton, IonContent, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonText, IonInput, IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react';
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setProteccionesEditHide, setProteccionesEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  regulacion: "",
  set_regulacion: "",
  tipo: "",
  corriente_corto_circuito:""
}
export const ProteccionesItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editProtecciones } = useSelector((state: RootState) => state.ui);
  const { interruptores = {}, tipo ="", corriente_corto_circuito } = activeEquipo;
  const { regulacion = "", set_regulacion = ""} = interruptores;
  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setProteccionesEditShow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Interruptores
      </IonLabel>
      {(activeEquipo.type === 'INTERRUPTORES' && activeEquipo.tipo) && (<IonList>
        <IonItem lines="none">
          <IonText>Tipo</IonText>
          <IonNote slot="end">{tipo}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Regulación</IonText>
          <IonNote slot="end">{regulacion}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Set regulacion</IonText>
          <IonNote slot="end">{set_regulacion}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cte cto circuito</IonText>
          <IonNote slot="end">{corriente_corto_circuito}</IonNote>
        </IonItem>
      </IonList>)}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">{(activeEquipo.type === 'INTERRUPTORES' && (set_regulacion || regulacion)) ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editProtecciones && <ProteccionesModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
};

const ProteccionesModal: FC = () => {
  const dispatch = useDispatch();

  const { editProtecciones } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { regulacion, set_regulacion, tipo, corriente_corto_circuito  } =
    formValues;
  useEffect(() => {
    if (activeEquipo.interruptores) {
      const aac = {
        regulacion: activeEquipo.interruptores.regulacion,
        set_regulacion: activeEquipo.interruptores.set_regulacion,
        tipo: activeEquipo.tipo,
        corriente_corto_circuito: activeEquipo.corriente_corto_circuito,
      };
      setFormValues(aac);
    } else {
      setFormValues(initForm);
    }
  }, [activeEquipo]);
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    interruptores: activeEquipo.interruptores,
    tipo: activeEquipo.tipo,
    corriente_corto_circuito: activeEquipo.corriente_corto_circuito
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.interruptores = {
      regulacion,
      set_regulacion
    };
    equipo.tipo = tipo;
    equipo.corriente_corto_circuito = corriente_corto_circuito;
    dispatch(startEquipoUpdated(equipo));
    dispatch(setProteccionesEditHide());
  };

  const handleClose = () => {
    dispatch(setProteccionesEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editProtecciones} cssClass="modal-banco-baterias">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Interruptor</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
          <IonItem>
              <IonLabel className="item-label" position="floating">
                Tipo
              </IonLabel>
              <IonInput
                type="text"
                name="tipo"
                value={tipo}
                onIonChange={handleInputChange}
                placeholder="Tipo"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
              Regulación
              </IonLabel>
              <IonInput
                type="text"
                name="regulacion"
                value={regulacion}
                onIonChange={handleInputChange}
                placeholder="Regulación"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Set Regulación{" "}
              </IonLabel>
              <IonInput
                type="number"
                name="set_regulacion"
                value={set_regulacion}
                onIonChange={handleInputChange}
                placeholder="Set Regulación"
                inputMode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Cte cto circuito
              </IonLabel>
              <IonInput
                type="text"
                name="corriente_corto_circuito"
                value={corriente_corto_circuito}
                onIonChange={handleInputChange}
                placeholder="Cte cto circuito"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};
