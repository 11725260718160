import { IonButton, IonContent, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonText, IonInput, IonHeader, IonToolbar, IonTitle, IonButtons, IonSelect, IonSelectOption } from '@ionic/react';
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setAlimentadoresCCEditHide, setAlimentadoresCCEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  conductor_positivo: "",
  aislamiento_conductor_positivo: "",
  conductor_negativo: "",
  aislamiento_conductor_negativo: "",
  equipo_relacionado: "",
}

const aislamiento = [
  {id: 1, name: "THHN/THWN-2"},
  {id: 2, name: "XLPE"},
  {id: 3, name: "THW"}
];

export const AlimentadoresCCItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editAlimentadoresCC } = useSelector((state: RootState) => state.ui);
  const { alimentadores_cc = {} } = activeEquipo;
  const { conductor_positivo = "", aislamiento_conductor_positivo = "", conductor_negativo = "", aislamiento_conductor_negativo = "", equipo_relacionado = "" } = alimentadores_cc;

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setAlimentadoresCCEditShow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Alimentadores CC.
      </IonLabel>
      {Object.keys(alimentadores_cc).length !== 0 && <IonList>
        <IonItem lines="none">
          <IonText>Coductor Positivo</IonText>
          <IonNote slot="end">{conductor_positivo}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Aisl. cond. positivo</IonText>
          <IonNote slot="end">{aislamiento_conductor_positivo}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Conductor negativo</IonText>
          <IonNote slot="end">{conductor_negativo}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Aisl. cond. negativo</IonText>
          <IonNote slot="end">{aislamiento_conductor_negativo}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Equipo relacionado</IonText>
          <IonNote slot="end">{equipo_relacionado}</IonNote>
        </IonItem>
      </IonList>}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">{Object.keys(alimentadores_cc).length !== 0 ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editAlimentadoresCC && <AlimentadoresCCModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
};

const AlimentadoresCCModal: FC = () => {
  const dispatch = useDispatch();

  const { editAlimentadoresCC } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { conductor_positivo, aislamiento_conductor_positivo, conductor_negativo, aislamiento_conductor_negativo, equipo_relacionado } = formValues;
  useEffect(() => {
    if (activeEquipo.alimentadores_cc) {
      const acc = {
        conductor_positivo: activeEquipo.alimentadores_cc.conductor_positivo,
        aislamiento_conductor_positivo: activeEquipo.alimentadores_cc.aislamiento_conductor_positivo,
        conductor_negativo: activeEquipo.alimentadores_cc.conductor_negativo,
        aislamiento_conductor_negativo: activeEquipo.alimentadores_cc.aislamiento_conductor_negativo,
        equipo_relacionado: activeEquipo.alimentadores_cc.equipo_relacionado,
      };
      setFormValues(acc)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo.alimentadores_cc])
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    alimentadores_cc: activeEquipo.alimentadores_cc,

  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.alimentadores_cc = {
      conductor_positivo,
      aislamiento_conductor_positivo,
      conductor_negativo,
      aislamiento_conductor_negativo,
      equipo_relacionado
    }
    dispatch(startEquipoUpdated(equipo));
    dispatch(setAlimentadoresCCEditHide())
  };

  const handleClose = () => {
    dispatch(setAlimentadoresCCEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editAlimentadoresCC} cssClass='modal-banco-baterias'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Alimentadores CC.</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
          <IonList>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Conductor positivo
              </IonLabel>
              <IonInput
                type="text"
                name="conductor_positivo"
                value={conductor_positivo}
                onIonChange={handleInputChange}
                placeholder="Conductor positivo"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Aisl. cond positivo</IonLabel>
              <IonSelect name="aislamiento_conductor_positivo" value={aislamiento_conductor_positivo} onIonChange={handleInputChange}>
                {aislamiento.map((ac: any) => (
                  <IonSelectOption key={ac.name} value={ac.name}>
                    {ac.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Conductor negativo
              </IonLabel>
              <IonInput
                type="text"
                name="conductor_negativo"
                value={conductor_negativo}
                onIonChange={handleInputChange}
                placeholder="Conductor negativo"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Aisl. cond negativo</IonLabel>
              <IonSelect name="aislamiento_conductor_negativo" value={aislamiento_conductor_negativo} onIonChange={handleInputChange}>
                {aislamiento.map((ac: any) => (
                  <IonSelectOption key={ac.name} value={ac.name}>
                    {ac.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Equipo relacionado
              </IonLabel>
              <IonInput
                type="text"
                name="equipo_relacionado"
                value={equipo_relacionado}
                onIonChange={handleInputChange}
                placeholder="Equipo relacionado"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
            </IonList>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}