export const types = {
    authChecking: '[auth] Cheking login state',
    authChekingFinish: '[auth] Finish cheking login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authStartRegister: '[auth] Start register',
    authStartTokenRenew: '[auth] Star token renew',
    authLogout: '[auth] Logout',

    centralStartLoad: '[central] Load centrales',
    centralStartAdd: '[central] Central start add',
    centralStartUpdated: '[central] Central start updated',
    centralStartDeleted: '[central] Central start deleted',
    centralSetActive: '[central] Central setactive',
    centralClearActive: '[central] Central clear active',
    centralCleared: '[central] Central cleared all',

    marcaStartLoad: '[marca] Load marcas',
    marcaStartAdd: '[marca] Marca start add',
    marcaStartUpdated: '[marca] Marca start updated',
    marcaStartDeleted: '[marca] Marca start deleted',
    marcaSetActive: '[marca] Marca setactive',
    marcaClearActive: '[marca] Marca clear active',
    marcaCleared: '[marca] Marca cleared all',

    canalStartLoad: '[canal] Load canalizaciones',
    canalStartAdd: '[canal] Canalizacion start add',
    canalStartUpdated: '[canal] Canalizacion start updated',
    canalStartDeleted: '[canal] Canal start deleted',
    canalSetActive: '[canal] Canalizacion setactive',
    canalClearActive: '[canal] Canalizacion clear active',
    canalCleared: '[canal] Canalizacion cleared all',

    typeStartLoad: '[type] Load types',
    typeStartAdd: '[type] Type  start add',
    typeStartUpdated: '[type] Type  start updated',
    typeStartDeleted: '[type] Type start deleted',
    typeSetActive: '[type] Type  setactive',
    typeClearActive: '[type] Type  clear active',
    typeCleared: '[type] Type  cleared all',

    labelStartLoad: '[label] Load labels',
    labelStartAdd: '[label] Label  start add',
    labelStartUpdated: '[label] Label  start updated',
    labelStartDeleted: '[label] Label start deleted',
    labelSetActive: '[label] Label  setactive',
    labelClearActive: '[label] Label  clear active',
    labelCleared: '[label] Label  cleared all',

    equipoStartLoad: '[equipo] Load equipos',
    equipoStartAdd: '[equipo] Equipo start add',
    equipoStartUpdated: '[equipo] Equipo start updated',
    equipoStartDeleted: '[equipo] Equipo start deleted',
    equipoSetActive: '[equipo] Equipo setactive',
    equipoClearActive: '[equipo] Equipo clear active',
    equipoCleared: '[equipo] Equipo cleared all',

    uiModalEstadoEditShow: '[ui] Modal estado edit show',
    uiModalEstadoEditHide: '[ui] Modal estado edit hide',
    uiModalNumeroEditShow: '[ui] Modal numero edit show',
    uiModalNumeroEditHide: '[ui] Modal numero edit hide',
    uiModalTagSetShow: '[ui] Modal tag set show',
    uiModalTagSetHide: '[ui] Modal tag set hide',
    uidModalDescriptionSetShow: '[ui] Modal description set show',
    uidModalDescriptionSetHide: '[ui] Modal description set hide',
    uiChekingCreate: '[ui] Equipo Cheking create',
    uiunChekingCreate: '[ui] Equipo unCheking create',
    uiModalCentralSetShow: '[ui] Set modal central show',
    uiModalCentralSetHide: '[ui] Set modal central hide',
    uiModalMarcaSetShow: '[ui] Set modal marca show',
    uiModalMarcaSetHide: '[ui] Set modal marca hide',   
    uiModalTypeSetShow: '[ui] Set modal type show',
    uiModalTypeSetHide: '[ui] Set modal type hide',
    uiModalLabelSetShow: '[ui] Set modal name show',
    uiModalLabelSetHide: '[ui] Set modal name hide',
    uiModalCanalSetShow: '[ui] Set modal canalizacion show',
    uiModalCanalSetHide: '[ui] Set modal canalizacion hide',
    uiModalEquipoSetShow: '[ui] Set modal equipo show',
    uiModalEquipoSetHide: '[ui] Set modal equipo hide',
    uiToastSetActive: '[ui] Set toast active',
    uiToastSetInactive: '[ui] Set toast inactive',
    uiToastSetShow: '[ui] Set toats show',
    uiToastSetHide: '[ui] Set toast hide',
    uiEditModalSetShow: '[ui] Set edit modal show',
    uiEditModalSetHide: '[ui] Set edit modal hide',
    uiEditModalAddDescriptionShow: '[ui] Add description to edit modal',
    uiEditModalAddDescriptionHide: '[ui] Remove description from edit modal',
    uiEditModalAddLocalDescriptionShow: '[ui] Add local description to edit modal',
    uiEditModalAddLocalDescriptionHide: '[ui] Remove local description from edit modal',

    uiEditModeloSetShow: '[ui] Set edit modale show',
    uiEditModeloSetHide: '[ui] Set edit modale hide',

    uiEditCorrienteNominalSetShow: '[ui] Set edit corriente nominal show',
    uiEditCorrienteNominalSetHide: '[ui] Set edit corriente nominal hide',

    uiEditVoltajeNominalShow: '[ui] Set edit voltaje nominal show',
    uiEditVoltajeNominalHide: '[ui] Set edit voltaje nominal hide',

    uiEditFrecuenciaSetShow: '[ui] Set edit fecuencia show',
    uiEditFrecuenciaSetHide: '[ui] Set edit fecuencia hide',

    uiEditUbicacionSetShow: '[ui] Set edit ubicacion show',
    uiEditUbicacionSetHide: '[ui] Set edit ubicacion hide',

    uiEditFechaInstalacionSetShow: '[ui] Set edit fecha instalacion show',
    uiEditFechaInstalacionSetHide: '[ui] Set edit fecha instalacion hide',

    uiModalEditMarcaSetShow: '[ui] Set modal edit marca show',
    uiModalEditMarcaSetHide: '[ui] Set modal edit marca hide',
    
    uiModalEditCapacidadSetShow: '[ui] Set modal edit capacidad show',
    uiModalEditCapacidadSetHide: '[ui] Set modal edit capacidad hide',

    uiModalEditUnidadesSetShow: '[ui] Set modal edit unidades show',
    uiModalEditUnidadesSetHide: '[ui] Set modal edit unidades hide',

    uiModalEditTablerosSetShow: '[ui] Set modal edit tableros show',
    uiModalEditTablerosSetHide: '[ui] Set modal edit tableros hide',
    
    uiModalEditProteccionesSetShow: '[ui] Set modal edit proteciones show',
    uiModalEditProteccionesSetHide: '[ui] Set modal edit proteciones hide',

    uiModalEditMedicionesACSetShow: '[ui] Set modal edit medicionesAC show',
    uiModalEditMedicionesACSetHide: '[ui] Set modal edit medicionesAC hide',

    uiModalEditMedicionesCCSetShow: '[ui] Set modal edit medicionesCC show',
    uiModalEditMedicionesCCSetHide: '[ui] Set modal edit medicionesCC hide',

    uiModalEditCanalizacionSetShow: '[ui] Set modal edit canalizaciones show',
    uiModalEditCanalizacionSetHide: '[ui] Set modal edit canalizaciones hide',

    uiModalEditAlimentadoresACSetShow: '[ui] Set modal edit alimentadores ac show',
    uiModalEditAlimentadoresACSetHide: '[ui] Set modal edit alimentadores ac hide',

    uiModalEditAlimentadoresCCSetShow: '[ui] Set modal edit alimentadores cc show',
    uiModalEditAlimentadoresCCSetHide: '[ui] Set modal edit alimentadores cc hide',

    uiModalEditCircuitosMediaTensionSetShow: '[ui] Set modal edit circuitos media tension cc show',
    uiModalEditCircuitosMediaTensionSetHide: '[ui] Set modal edit circuitos media tension cc hide',

    uiModalEditTrasformadoresMedicionSetShow: '[ui] Set modal edit transformadores medicion cc show',
    uiModalEditTrasformadoresMedicionSetHide: '[ui] Set modal edit transformadores medicion cc hide',

    uiModalEditTrasformadoresSetShow: '[ui] Set modal edit transformadores show',
    uiModalEditTrasformadoresSetHide: '[ui] Set modal edit transformadores hide',

    uiModalEditGeneradoresSetShow: '[ui] Set modal edit generadores show',
    uiModalEditGeneradoresSetHide: '[ui] Set modal edit generadores hide',

    uiModalEditEquipoRespladoSetShow: '[ui] Set modal edit equipo resplado show',
    uiModalEditEquipoRespladoSetHide: '[ui] Set modal edit equipo resplado hide',

    uiModalEditNumeroCeldaSetShow: '[ui] Set modal edit numero celda show',
    uiModalEditNumeroCeldaSetHide: '[ui] Set modal edit numero celda hide',

    uiModalEditRectificadorSetShow: '[ui] Set modal edit rectificador show',
    uiModalEditRectificadorSetHide: '[ui] Set modal edit rectificador hide',

    uiModalEditInversorSetShow: '[ui] Set modal edit inversor show',
    uiModalEditInversorSetHide: '[ui] Set modal edit inversor hide',

    uiModalEditUpsSetShow: '[ui] Set modal edit ups show',
    uiModalEditUpsSetHide: '[ui] Set modal edit ups hide',

    uiModalEditAiresSetShow: '[ui] Set modal edit aires show',
    uiModalEditAiresSetHide: '[ui] Set modal edit aires hide',

    uiModalEditTransferenciasAutomaticasSetShow: '[ui] Set modal edit transferencias automaticas show',
    uiModalEditTransferenciasAutomaticasSetHide: '[ui] Set modal edit transferencias automaticas hide',

    uiModalEditCeldasMtoSetShow: '[ui] Set modal edit celdas mto automaticas show',
    uiModalEditCeldasMtoSetHide: '[ui] Set modal edit celdas mto automaticas hide',

    uiModalUpdatePasswordSetShow: '[ui] Set modal update password show',
    uiModalUpdatePasswordSetHide: '[ui] Set modal update password hide',

    uiSetActiveLoading: '[ui] Set Active loadin',
    uiSetInactiveLoading: '[ui] Set Inactive Loadin',
    
    uiSetActiveAlert:'[ui] Set Active Alert',
    uiSetInactiveAlert: '[ui] Set inactive Alert',

    imageStartLoadImages: '[images] Load images from url',
    imagesSetDescription: '[images] Set image cloud description',
    imagesStartImageUpdated: '[images] Start image updated',
    imageStartSaveImages: '[images] Start save images',
    imageStartDeleteImages: '[images] Start delete images',
    imageStartLocalLoadImages : '[images] Load local images',
    imageSetLocalSaveImages: '[images] Load local save images',
    imageSetLocalImageDescription: '[images] Load local image description',    
    imageStartLocalDelete: '[images] Start local delete',
    imageStartClearLocal: '[images] Start clear local images',
    imageSetActiveImage: '[images] Set active Image from server',
    imageSetInactiveImage: '[images] Set inactive image from server',
    imageSetActiveLocalImage: '[images] Set active Image from local',
    imageSetInactiveLocalImage: '[images] Set inactive image from local',

}