import { FC } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { homeOutline, layersOutline, listOutline } from "ionicons/icons";
import Home from "../../pages/Home";
import Equipo from "../../pages/Equipo";
import Equipos from "../../pages/Equipos";
import Listas from "../../pages/Listas";
import Images from "../../pages/Images";
import Gallery from "../../pages/Gallery";

export const DashboardRoutes: FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/listas">
              <Listas />
            </Route>
            <Route exact path="/equipos">
              <Equipos />
            </Route>
            <Route exact path="/equipo">
              <Equipo />
            </Route>
            <Route exact path="/images">
              <Images />
            </Route>
            <Route exact path="/gallery">
              <Gallery />
            </Route>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={homeOutline} />
              <IonLabel>Inicio</IonLabel>
            </IonTabButton>
            <IonTabButton tab="listas" href="/listas">
              <IonIcon icon={layersOutline} />
              <IonLabel>Listas</IonLabel>
            </IonTabButton>
            <IonTabButton tab="equipos" href="/equipos">
              <IonIcon icon={listOutline} />
              <IonLabel>Equipos</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};
