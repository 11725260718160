import { IonButton, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonRefresher, IonRefresherContent, IonText } from "@ionic/react";
import { FC, useEffect } from "react";
import { addCircleOutline, chevronForwardOutline, createOutline, trashOutline } from 'ionicons/icons';
import { useDispatch, useSelector } from "react-redux";
import { clearActiveEquipo, equipoSetActive, startLoadEquipos } from "../../actions/equipos";
import { RootState } from "../../reducers/rootReducer";
import { setModalEquipoShow, startSetActiveAlert } from '../../actions/ui';
import { AlertComponent } from "../ui/AlertComponent";
import { RefresherEventDetail } from "@ionic/core";

export const EquipoListScreen: FC = ({ history }: any) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(startLoadEquipos())        
    }, [dispatch]);

    const { equipos} = useSelector((state: RootState) => state.equipo);
    
       const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        dispatch(startLoadEquipos());
        setTimeout(() => {
            event.detail.complete();
        }, 1500)
    };
    const handleDelete = (equipo: any) => {
        const alert = {
            active: true,
            header: "Eliminar equipo",
            message: `El equipo ${equipo.nombre_equipo} ${equipo.tag} será eliminado`,
            tipo: 'equipo'
        }
        dispatch(startSetActiveAlert(alert))
        dispatch(equipoSetActive(equipo));
    }

    const onDesactive = () => {
        dispatch(clearActiveEquipo());
    };

    const handleUpdate = (equipo: any) => {
        dispatch(equipoSetActive(equipo));
        history.push('/equipo')
    };

    const handleAdd = () => {
        dispatch(clearActiveEquipo());
        dispatch(setModalEquipoShow());
    };

    return (
        <>
            <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <IonList>
                <IonListHeader>
                    <IonLabel class="title">Equipos</IonLabel>
                    <IonButton onClick={handleAdd}><IonIcon className="icon-list" color="success" icon={addCircleOutline}></IonIcon></IonButton>
                </IonListHeader>

                {
                    equipos.map((e: any) =>
                    (<IonItemSliding key={e.id}>
                        <IonItem>
                            <IonText>{e.nombre_equipo} {e.tag}</IonText>
                            <IonIcon slot="end" icon={chevronForwardOutline}></IonIcon>
                        </IonItem>
                        <IonItemOptions side="end">
                            <IonItemOption color="white" onClick={() => { handleUpdate(e) }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
                            <IonItemOption color="white" onClick={() => { handleDelete(e) }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
                        </IonItemOptions>
                    </IonItemSliding>
                    )
                    )
                }
                
            </IonList>
             {/* <EquipoScreen history={history} /> */}
            <AlertComponent onDesactive={onDesactive} />
        </>
    );
}