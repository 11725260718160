import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from '@ionic/react'
import { chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setAlimentadoresACEditHide, setAlimentadoresACEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  aislamiento_conductor_fase: "",
  conductor_fase: "",
  conductor_neutro: "",
  aislamiento_conductor_neutro: "",
  conductor_proteccion: "",
  aislamiento_conductor_proteccion: "",
  equipo_relacionado: "",
}

const aislamiento = [
  {id: 1, name: "THHN/THWN-2"},
  {id: 2, name: "XLPE"},
  {id: 3, name: "THW"}
];

export const AlimentadoresACItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);
  const { editAlimentadoresAC } = useSelector((state: RootState) => state.ui);
  const { alimentadores_ac = {} } = activeEquipo;
  const { aislamiento_conductor_fase = "", conductor_fase = "", conductor_neutro = "", aislamiento_conductor_neutro = "", conductor_proteccion = "", aislamiento_conductor_proteccion = "", equipo_relacionado= "" } = alimentadores_ac;

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setAlimentadoresACEditShow())

  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Alimentadores AC.
      </IonLabel>
      {Object.keys(alimentadores_ac).length !== 0 && <IonList>
        <IonItem lines="none">
          <IonText>Aisl. cond. fase</IonText>
          <IonNote slot="end">{aislamiento_conductor_fase}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cond. fase</IonText>
          <IonNote slot="end">{conductor_fase}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cond. neutro</IonText>
          <IonNote slot="end">{conductor_neutro}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Aisl. cond. neutro</IonText>
          <IonNote slot="end">{aislamiento_conductor_neutro}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Cond. protección</IonText>
          <IonNote slot="end">{conductor_proteccion}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Aisl. cond. protección</IonText>
          <IonNote slot="end">{aislamiento_conductor_proteccion}</IonNote>
        </IonItem>
        <IonItem lines="none">
          <IonText>Equipo relacionado</IonText>
          <IonNote slot="end">{equipo_relacionado}</IonNote>
        </IonItem>
      </IonList>}
      <IonItemSliding>
        <IonItem lines="none">
          <IonLabel color="danger">{Object.keys(alimentadores_ac).length !== 0 ? 'Editar' : 'Agregar'}</IonLabel>
          <IonIcon icon={chevronForwardOutline}></IonIcon>
          {editAlimentadoresAC && <AlimentadoresACModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
};

const AlimentadoresACModal: FC = () => {
  const dispatch = useDispatch();

  const { editAlimentadoresAC } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { aislamiento_conductor_fase, conductor_fase, conductor_neutro, aislamiento_conductor_neutro, conductor_proteccion, aislamiento_conductor_proteccion, equipo_relacionado } = formValues;
  useEffect(() => {
    if (activeEquipo.alimentadores_ac) {
      const aac = {
        aislamiento_conductor_fase: activeEquipo.alimentadores_ac.aislamiento_conductor_fase,
        conductor_fase: activeEquipo.alimentadores_ac.conductor_fase,
        conductor_neutro: activeEquipo.alimentadores_ac.conductor_neutro,
        aislamiento_conductor_neutro: activeEquipo.alimentadores_ac.aislamiento_conductor_neutro,
        conductor_proteccion: activeEquipo.alimentadores_ac.conductor_proteccion,
        aislamiento_conductor_proteccion: activeEquipo.alimentadores_ac.aislamiento_conductor_proteccion,
        equipo_relacionado: activeEquipo.alimentadores_ac.equipo_relacionado,
      };
      setFormValues(aac)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo.alimentadores_ac])
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    alimentadores_ac: activeEquipo.alimentadores_ac,

  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.alimentadores_ac = {
      aislamiento_conductor_fase,
      conductor_fase,
      conductor_neutro,
      aislamiento_conductor_neutro,
      conductor_proteccion,
      aislamiento_conductor_proteccion,
      equipo_relacionado,
    }
    dispatch(startEquipoUpdated(equipo));
    dispatch(setAlimentadoresACEditHide())
  };

  const handleClose = () => {
    dispatch(setAlimentadoresACEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editAlimentadoresAC} cssClass='modal-celdasMT'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Alimentadores AC.</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonList>
            <IonItem>
              <IonLabel>Aisl. cond fase</IonLabel>
              <IonSelect name="aislamiento_conductor_fase" value={aislamiento_conductor_fase} onIonChange={handleInputChange}>
                {aislamiento.map((ac: any) => (
                  <IonSelectOption key={ac.name} value={ac.name}>
                    {ac.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Conductor fase
              </IonLabel>
              <IonInput
                type="text"
                name="conductor_fase"
                value={conductor_fase}
                onIonChange={handleInputChange}
                placeholder="Conductor fase"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Conductor neutro
              </IonLabel>
              <IonInput
                type="text"
                name="conductor_neutro"
                value={conductor_neutro}
                onIonChange={handleInputChange}
                placeholder="Conductor neutro"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Aisl. cond. neutro</IonLabel>
              <IonSelect name="aislamiento_conductor_neutro" value={aislamiento_conductor_neutro} onIonChange={handleInputChange}>
                {aislamiento.map((ac: any) => (
                  <IonSelectOption key={ac.name} value={ac.name}>
                    {ac.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Conductor protección
              </IonLabel>
              <IonInput
                type="text"
                name="conductor_proteccion"
                value={conductor_proteccion}
                onIonChange={handleInputChange}
                placeholder="Conductor protección"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Aisl. cond. proteccion</IonLabel>
              <IonSelect name="aislamiento_conductor_proteccion" value={aislamiento_conductor_proteccion} onIonChange={handleInputChange}>
                {aislamiento.map((ac: any) => (
                  <IonSelectOption key={ac.name} value={ac.name}>
                    {ac.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel className="item-label" position="floating">
                Equipo relacionado
              </IonLabel>
              <IonInput
                type="text"
                name="equipo_relacionado"
                value={equipo_relacionado}
                onIonChange={handleInputChange}
                placeholder="Equipo relacionado"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>
            </IonList>
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}