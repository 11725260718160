import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { closeOutline, createOutline, trashOutline } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEquipoUpdated } from '../../../actions/equipos'
import { setActiveLoading, setVolatajeNominalEditHide, setVoltajeNominalEditShow } from '../../../actions/ui'
import { RootState } from '../../../reducers/rootReducer'

const initForm = {
  voltajeNominal: '',
}

export const VoltajeNominalItem: FC = () => {
  const dispatch = useDispatch();
  const { activeEquipo: { voltajeNominal } } = useSelector((state: RootState) => state.equipo);
  const {editVoltajeNominal} = useSelector((state: RootState) => state.ui);  

  const handleDelete = () => {
    console.log('click')
  }
  const handleUpdate = () => {
    dispatch(setVoltajeNominalEditShow())
    
  }
  return (
    <IonItem>
      <IonLabel className="item-label" position="stacked" color="primary">
        Voltaje Nominal
      </IonLabel>
      <IonItemSliding>
        <IonItem lines="none">
        <IonLabel>{voltajeNominal}</IonLabel>
        {editVoltajeNominal && <VoltajeNominalModal />}
        </IonItem>
        <IonItemOptions side="end" color="light">
          <IonItemOption color="light" onClick={() => { handleUpdate() }}><IonIcon className="icon-list" color="success" ariaLabel="Editar" title="Editar" icon={createOutline}></IonIcon></IonItemOption>
          <IonItemOption color="light" onClick={() => { handleDelete() }}><IonIcon className="icon-list" color="danger" icon={trashOutline}></IonIcon></IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonItem>
  )
}

const VoltajeNominalModal: FC = () => {
  const dispatch = useDispatch();

  const { editVoltajeNominal } = useSelector((state: RootState) => state.ui);
  const { activeEquipo } = useSelector((state: RootState) => state.equipo);

  const [formValues, setFormValues] = useState(initForm);
  const { voltajeNominal } = formValues;
  useEffect(() => {
    if (activeEquipo.voltajeNominal) {
      const vn = {
        voltajeNominal: activeEquipo.voltajeNominal,
      };
      setFormValues(vn)
    } else {
      setFormValues(initForm)
    }
  }, [activeEquipo.voltajeNominal])

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const equipo = {
    tag: activeEquipo.tag,
    nombre_equipo: activeEquipo.nombre_equipo,
    descripcion_equipo: activeEquipo.descripcion_equipo,
    central: activeEquipo.central !== null ? activeEquipo.central._id : "",
    numero_equipo: activeEquipo.numero_equipo,
    type: activeEquipo.type,
    voltajeNominal: activeEquipo.voltajeNominal,

  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(setActiveLoading());
    equipo.voltajeNominal = voltajeNominal
    dispatch(startEquipoUpdated(equipo));
    dispatch(setVolatajeNominalEditHide())
  };

  const handleClose = () => {
    dispatch(setVolatajeNominalEditHide());
  };
  return (
    <IonContent>
      <IonModal isOpen={editVoltajeNominal} cssClass='modal-unit-iput'>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle color="dark">Voltaje Nominal</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleClose}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="marca-container">
          <form onSubmit={handleUpdate}>
            <IonItem lines="inset">
              <IonInput
                type="number"
                inputMode="numeric"
                name="voltajeNominal"
                value={voltajeNominal}
                onIonChange={handleInputChange}
                placeholder="Voltaje nominal"
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonButton slot="end" fill="clear" type="submit">
                Guardar
              </IonButton>
              <IonButton slot="end" fill="clear" onClick={handleClose}>
                Cancelar
              </IonButton>
            </IonItem>          
          </form>
        </IonContent>
      </IonModal>
    </IonContent>
  );
}
